import { useLocation } from "@reach/router";
import { Button, message, Pagination, Skeleton, Spin } from "antd";
import { cloneDeep, isArray, isEmpty, map, set } from "lodash";
import get from "lodash.get";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { not_foundIcon } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getProfiles from "../../../newApi/profile/getProfiles";
import getProfileRobotProfitSummary from "../../../newApi/robots/getProfileRobotProfitSummary";
import getRobotTransactions from "../../../newApi/robots/getRobotTransactions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { formatDecimalNumber } from "../../../utilities/common-function";
import { numberDecimal } from "../../../utilities/startUp";
import Layout from "../../general/components/Layout";
import NewHeader from "../../general/components/NewHeader";
import DailyProfitReportDrawer from "../components/DailyProfitReportDrawer";

const PAGESIZE = 10;

// markup
let timeoutFunc;
const RobotPage = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  // const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [arrayLoading, setArrayLoading] = useState(true);
  const [productRecordSelected, setProductRecordSelected] = useState({
    // 0 - total, 1 - month
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  });
  const [productCoinSelected, setProductCoinSelected] = useState({
    // 0 - today, 1 - total
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  });
  const [productRecordPagination, setProductRecordPagination] = useState({
    1: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
    2: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
    3: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
    4: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
  });
  const [productCoinPagination, setProductCoinPagination] = useState({
    1: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
    2: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
    3: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
    4: {
      0: {
        page: 1,
        total: 0,
        data: [],
      },
      1: {
        page: 1,
        total: 0,
        data: [],
      },
    },
  });
  const [tabKey, setTabKey] = useState("2");
  const [totalProfit, setTotalProfit] = useState(null);
  const [todayProfit, setTodayProfit] = useState(null);
  const [productProfit, setProductProfit] = useState({});
  // const [productCoinProfit, setProductCoinProfit] = useState({});
  // const [productRecordProfit, setProductRecordProfit] = useState({});
  const [showRecord, setShowRecord] = useState(true);
  const [codeType, setCodeType] = useState("all");
  const [drawerVisible, setDrawerVisible] = useState(false);
  // const accessKey = get(props.user, "accessKey");
  const [filterList, setFilterList] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [robotProfiles, setRobotProfiles] = useState([]);
  const [profile, setProfile] = useState({});
  // let profile = get(props.user, "user.profile");

  useEffect(() => {
    getProfileAPI();
    // getSummaryReport();
  }, []);

  useEffect(() => {
    // getTotalProfitAPI();
    // getData();
    // getSummaryReport();
  }, [robotProfiles]);

  // useEffect(() => {
  //   switch (tabKey) {
  //     case "2":
  //       switch (codeType) {
  //         case strategyCode.highFrequency:
  //         case strategyCode.highFrequency3:
  //         case strategyCode.highFrequency4:
  //           getSummaryReport(tabKey, codeType);
  //           break;
  //         default:
  //           getSummaryReport(tabKey, "HF");
  //           break;
  //       }
  //       break;

  //     default:
  //       break;
  //   }
  // }, [tabKey, codeType]);

  function getProfileAPI() {
    if (get(props.user, "user.profile._id")) {
      getProfiles(1, 0, {
        _id: get(props.user, "user.profile._id"),
      }).then((res) => {
        let profileData = get(res, "data[0]");
        let robotProfilesData = get(profileData, "robotProfiles");
        robotProfilesData = map(robotProfilesData, (item, index) => {
          return get(item, "robotProfileId");
        });
        // console.log(robotProfilesData);
        setProfile(profileData);
        setRobotProfiles(robotProfilesData);
      });
    }
  }

  function getSummaryReport(tab, code) {
    let extraFilter = {};
    if (code) {
      extraFilter.strategyCode = code;
    }
  }

  useEffect(() => {
    getTotalProfitAPI();
    getData();
  }, [
    productCoinSelected,
    productRecordSelected,
    tabKey,
    showRecord,
    codeType,
    robotProfiles,
  ]);

  function getData(skip = 0) {
    setArrayLoading(true);

    if (get(props.user, "user.profile._id") && !isEmpty(robotProfiles)) {
      let filterGroup = {};
      if (!showRecord) {
        filterGroup.type = "coin";
        if (get(productCoinSelected, tabKey) === 0) {
          filterGroup.timestampMin = moment().startOf("D").toDate();
          filterGroup.timestampMax = moment().endOf("D").toDate();
        }
      } else {
        if (get(productRecordSelected, tabKey) === 0) {
          filterGroup.timestampMin = moment().startOf("D").toDate();
          filterGroup.timestampMax = moment().endOf("D").toDate();
        } else {
          filterGroup.type = "day";
        }
      }

      clearTimeout(timeoutFunc);

      timeoutFunc = setTimeout(() => {
        getRobotTransactions(PAGESIZE, skip, {
          profileIdIn: robotProfiles,
          ...filterGroup,
          showRecord,
        })
          .then((res) => {
            // console.log("res", res);
            let newData = cloneDeep(productCoinPagination);
            set(
              newData,
              `${tabKey}.${get(
                showRecord === false
                  ? productCoinSelected
                  : productRecordSelected,
                tabKey
              )}.data`,
              get(res, "data")
            );
            set(
              newData,
              `${tabKey}.${get(
                showRecord === false
                  ? productCoinSelected
                  : productRecordSelected,
                tabKey
              )}.total`,
              get(res, "total")
            );
            set(
              newData,
              `${tabKey}.${get(
                showRecord === false
                  ? productCoinSelected
                  : productRecordSelected,
                tabKey
              )}.page`,
              skip / PAGESIZE + 1
            );
            // console.log(newData);
            if (!showRecord) {
              setProductCoinPagination(newData);
            } else {
              setProductRecordPagination(newData);
            }

            setFilterList(filterGroup);

            setArrayLoading(false);
          })
          .catch((err) => {
            setArrayLoading(false);
            console.log(err);
            message.error(err?.message);
          });
      }, 100);
    } else {
      setArrayLoading(false);
    }
  }

  function getTotalProfitAPI() {
    // console.log("getTotalProfit", robotProfiles);
    if (!isEmpty(robotProfiles)) {
      // console.log("totalprofit", robotProfiles);
      getProfileRobotProfitSummary(1, 0, {
        profileIdIn: robotProfiles,
      })
        .then((res) => {
          // console.log("total", res);
          // console.log("totalProfit", get(res, "totalProfit"));
          setTotalProfit(get(res, "totalProfit"));
        })
        .catch((err) => {
          console.log(err);
        });

      let filterGroup = {};
      filterGroup.timestampMin = moment().startOf("D").toDate();
      filterGroup.timestampMax = moment().endOf("D").toDate();

      getProfileRobotProfitSummary(1, 0, {
        profileIdIn: robotProfiles,
        ...filterGroup,
      })
        .then((res) => {
          // console.log("today", res);
          // console.log("todayProfit", get(res, "totalProfit"));
          setTodayProfit(get(res, "totalProfit"));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const _renderRecordList = (tab, selectedType, productRecordPagination) => {
    let isShowDay = selectedType == 0;
    let relatedPagination =
      get(productRecordPagination, `${tab}.${selectedType}`) || {};
    let relatedData = get(relatedPagination, `data`) || [];
    // console.log("related data", relatedData);
    return (
      <>
        <div className="rounded-xl lightgreybox-bg-color  mx-1 py-1 px-2 mb-5 ">
          <div className=" flex flex-wrap justify-between  ">
            <div>{_renderRecordType()}</div>

            <div className="flex">
              <div className="flex justify-center items-center my-2">
                <span className="inline-block mr-2">
                  <Button
                    shape="round"
                    className={`p-2 ${
                      isShowDay ? "main-button-color" : "second-button-color"
                    }`}
                    onClick={(e) => {
                      setProductRecordSelected({
                        ...productRecordSelected,
                        [tab]: 0,
                      });
                    }}
                  >
                    {t("today", sourceKey.pairing)}
                  </Button>
                </span>
                <span className="inline-block ">
                  <Button
                    shape="round"
                    className={`p-2 ${
                      !isShowDay ? "main-button-color" : "second-button-color"
                    }`}
                    onClick={(e) => {
                      setProductRecordSelected({
                        ...productRecordSelected,
                        [tab]: 1,
                      });
                    }}
                  >
                    {t("total", sourceKey.pairing)}
                  </Button>
                </span>
              </div>
            </div>
          </div>
          <span className="inline-block text-sm font-semibold">
            {isShowDay
              ? t("profitToday", sourceKey.pairing)
              : t("totalProfit", sourceKey.pairing)}
          </span>
          {arrayLoading ? (
            <>
              <Skeleton active rows={5}></Skeleton>
            </>
          ) : isArray(relatedData) && !isEmpty(relatedData) ? (
            <React.Fragment>
              {map(relatedData, (item, index) => {
                return isShowDay ? (
                  <>
                    <div
                      className=" p-2  border-dotted border-b-2  "
                      key={index}
                      style={{ backgroundColor: "#20262F" }}
                    >
                      <div className="flex justify-between">
                        <div className="flex items-center">
                          {get(item, "populatedRobotId.symbol") +
                            "-" +
                            `${
                              get(item, "populatedRobotId._id")
                                ? `${get(
                                    item,
                                    "populatedRobotId._id"
                                  ).substring(
                                    get(item, "populatedRobotId._id").length - 4
                                  )}`
                                : ""
                            } ${
                              get(item, "remark")
                                ? `(${get(item, "populatedRobotId.remark")})`
                                : ""
                            }`}
                        </div>
                        <div
                          className="flex items-center text-xs"
                          // style={{ fontSize: "0.55rem" }}
                        >
                          <span>
                            {" "}
                            {t("orderNo", sourceKey.highFrequency)} :{" "}
                          </span>
                          <span className="">
                            {get(item, "_id")?.substring(get(item, "_id").length - 6)}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="flex items-center  text-xs">
                          {/* ITS:{" "}
                          {get(item, "populatedRobotId._id")
                            ? `${get(item, "populatedRobotId._id").substring(
                                get(item, "populatedRobotId._id").length - 4
                              )}`
                            : ""} */}
                          {get(item, "timestamp")
                            ? moment(get(item, "timestamp")).format(
                                "DD/MM/YYYY hh:mm:ss A"
                              )
                            : ""}
                        </div>

                        <div className="flex justify-end ">
                          <span
                            className={`${
                              get(item, "profileTransaction.trxType") === "IN"
                                ? "text-green-400"
                                : "text-red-400"
                            }  pr-1 text-xs`}
                          >
                            {get(item, "profileTransaction")
                              ? get(item, "profileTransaction.trxType") ===
                                "OUT"
                                ? `(${t(
                                    "deductVSDT",
                                    sourceKey.highFrequency
                                  )} ${formatDecimalNumber(
                                    get(item, "profileTransaction.amount"),
                                    2
                                  )} )`
                                : `(${t(
                                    "compesated",
                                    sourceKey.highFrequency
                                  )} )`
                              : ""}
                          </span>{" "}
                          {formatDecimalNumber(
                            get(item, "profit"),
                            numberDecimal
                          )}
                        </div>
                      </div>
                      {/* <div className="flex justify-between">
                        <span className="flex items-center  text-xs">
                          {get(item, "timestamp")
                            ? moment(get(item, "timestamp")).format(
                                "DD/MM/YYYY hh:mm:ss A"
                              )
                            : ""}
                        </span>
                        <span
                          className={`${
                            get(item, "profileTransaction.trxType") === "IN"
                              ? "text-green-400"
                              : "text-red-400"
                          } text-xs`}
                        >
                          {get(item, "profileTransaction")
                            ? get(item, "profileTransaction.trxType") === "OUT"
                              ? `(${t(
                                  "deductVSDT",
                                  sourceKey.highFrequency
                                )} ${formatDecimalNumber(
                                  get(item, "profileTransaction.amount"),
                                  2
                                )} )`
                              : ``
                            : ""}
                        </span>{" "}
                      </div> */}
                    </div>
                  </>
                ) : (
                  <div
                    className=" p-2 mb-2 flex justify-around items-center border-b-2"
                    style={{ cursor: "pointer" }}
                    key={index}
                    onClick={() => {
                      setDrawerVisible(true);
                      setSelectedData(item);
                    }}
                  >
                    <span className="inline-block mr-4">
                      {get(item, "date")
                        ? moment(get(item, "date")).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                    <span className="inline-block mr-4">
                      {t("profit", sourceKey.highFrequency)}
                    </span>
                    <span className="inline-block mr-4">
                      {formatDecimalNumber(
                        get(item, "totalProfit"),
                        numberDecimal
                      )}
                    </span>
                  </div>
                );
              })}
              <div className="flex justify-end items-center my-5">
                <span className="inline-block ">
                  <Pagination
                    size="small"
                    simple
                    total={get(relatedPagination, `total`, 0)}
                    pageSize={PAGESIZE}
                    current={get(relatedPagination, `page`)}
                    showSizeChanger={false}
                    responsive={true}
                    onChange={(page) => {
                      let newPaginationObj = cloneDeep(productRecordPagination);
                      set(
                        newPaginationObj,
                        `${tab}.${selectedType}.page`,
                        page
                      );
                      setProductRecordPagination(newPaginationObj);
                      getData((page - 1) * PAGESIZE);
                    }}
                  />
                </span>
              </div>
            </React.Fragment>
          ) : (
            <div className="py-9 pb-11">
              <div className="text-center flex justify-center mt-7">
                <img src={not_foundIcon} style={{ height: 70, width: 70 }} />
              </div>
              <div className="text-gray-400 flex justify-center">
                {t("noRecords", sourceKey.highFrequency)}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };
  
  const _renderCoinList = (tab, selectedType, productCoinPagination) => {
    let isShowDay = selectedType == 0;
    let relatedPagination =
      get(productCoinPagination, `${tab}.${selectedType}`) || {};
    let relatedData = get(relatedPagination, `data`) || [];
    // console.log("coin relatedData", relatedData);
    return (
      <>
        <div className="rounded-xl lightgreybox-bg-color  mx-1 py-1 px-2 mb-5 ">
          <div className="flex justify-between ">
            <div>{_renderRecordType()}</div>

            <div className="flex">
              <div className="flex justify-center items-center my-4">
                <span className="inline-block mr-2">
                  <Button
                    shape="round"
                    className={`p-2 ${
                      isShowDay ? "main-button-color" : "second-button-color"
                    }`}
                    onClick={(e) => {
                      setProductCoinSelected({
                        ...productRecordSelected,
                        [tab]: 0,
                      });
                    }}
                  >
                    {t("today", sourceKey.pairing)}
                  </Button>
                </span>
                <span className="inline-block ">
                  <Button
                    shape="round"
                    className={`p-2 ${
                      !isShowDay ? "main-button-color" : "second-button-color"
                    }`}
                    onClick={(e) => {
                      setProductCoinSelected({
                        ...productRecordSelected,
                        [tab]: 1,
                      });
                    }}
                  >
                    {t("total", sourceKey.pairing)}
                  </Button>
                </span>
              </div>
            </div>
          </div>
          <span className="inline-block text-sm font-semibold">
            {isShowDay
              ? t("profitToday", sourceKey.pairing)
              : t("totalProfit", sourceKey.pairing)}
          </span>
          {arrayLoading ? (
            <>
              <Skeleton active rows={5}></Skeleton>
            </>
          ) : isArray(relatedData) && !isEmpty(relatedData) ? (
            <React.Fragment>
              {map(relatedData, (item, index) => {
                return (
                  <div
                    className=" p-2 mb-3 flex items-center border-b-2"
                    // style={{ cursor: "pointer" }}
                    key={index}
                    // onClick={() => {
                    //   setDrawerVisible(true);
                    //   setSelectedData(item);
                    // }}
                  >
                    <span className="inline-block mr-4">{index + 1}</span>
                    <span className="flex justify-between items-center w-full">
                      <span className="inline-block mr-2">
                        {get(item, "symbol")}
                      </span>
                      <span className="inline-block">
                        {formatDecimalNumber(
                          get(item, "totalProfit"),
                          numberDecimal
                        )}
                      </span>
                    </span>
                  </div>
                );
              })}
              <div className="flex justify-end items-center my-5">
                <span className="inline-block ">
                  <Pagination
                    simple
                    size="small"
                    total={get(relatedPagination, `total`, 0)}
                    pageSize={PAGESIZE}
                    current={get(relatedPagination, `page`)}
                    showSizeChanger={false}
                    responsive={true}
                    onChange={(page) => {
                      let newPaginationObj = cloneDeep(productCoinPagination);
                      set(
                        newPaginationObj,
                        `${tab}.${selectedType}.page`,
                        page
                      );
                      setProductCoinPagination(newPaginationObj);
                      getData((page - 1) * PAGESIZE);
                    }}
                  />
                </span>
              </div>
            </React.Fragment>
          ) : (
            <div className="py-9 pb-11">
              <div className="text-center flex justify-center mt-7">
                <img src={not_foundIcon} style={{ height: 70, width: 70 }} />
              </div>
              <div className="text-gray-400 flex justify-center">
                {t("noRecords", sourceKey.highFrequency)}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const _renderRecordType = () => {
    return (
      <div className="flex justify-center items-center my-4">
        <span className="inline-block mr-2">
          <Button
            shape="round"
            className={`main-button-padding ${
              showRecord ? "main-button-color" : "second-button-color"
            }`}
            onClick={(e) => {
              setShowRecord(true);
            }}
          >
            {t("record", sourceKey.highFrequency)}
          </Button>
        </span>
        <span className="inline-block ">
          <Button
            shape="round"
            className={`main-button-padding ${
              !showRecord ? "main-button-color" : "second-button-color"
            }`}
            onClick={(e) => {
              setShowRecord(false);
            }}
          >
            {t("coin", sourceKey.highFrequency)}
          </Button>
        </span>
      </div>
    );
  };

  const _renderCodeType = () => {
    const codeTypeList = [
      {
        text: "All",
        value: "all",
      },
      {
        text: "HF1",
        value: "HF1",
      },
      {
        text: "HF3",
        value: "HF3",
      },
    ];
    return (
      <div className="flex justify-center items-center my-4">
        {map(codeTypeList, (item, index) => {
          return (
            <span className="mr-2">
              <Button
                style={{
                  borderColor: `${
                    get(item, "value") === codeType ? "#FCD535" : ""
                  }`,
                  color: `${get(item, "value") === codeType ? "#FCD535" : ""}`,
                }}
                onClick={() => {
                  setCodeType(get(item, "value"));
                }}
              >
                {get(item, "text")}
              </Button>
            </span>
          );
        })}
      </div>
    );
  };
  // console.log("outside", totalProfit);

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          {/* <NavHeader
            suffix={
              <div className="justify-end flex items-center">

                {t("transaction", sourceKey.highFrequency)}
              </div>
            }
            showBack={true}
          > */}
          <NewHeader>
            <div className="mx-4">
              <div className="grid grid-cols-4 gap-2 bg-main-color-gradient rounded-3xl mx-4 pt-2 pb-1">
                <div className="col-span-2 ">
                  <div className="mb-2  text-black">
                    <div className="font-semibold text-center text-2xl pt-4">
                      {formatDecimalNumber(todayProfit || 0, numberDecimal)}
                    </div>
                    <div className="pb-1.5 pt-1  rounded-md p-2 sm:px-10 mr-2 text-center">
                      {/* 今日盈利 <br /> */}
                      {t("profitToday", sourceKey.pairing)}
                    </div>
                  </div>
                </div>

                <div className="col-span-2">
                  <div className="mb-2  text-black">
                    <div className="font-semibold text-center text-2xl pt-4">
                      {formatDecimalNumber(totalProfit || 0, numberDecimal)}
                    </div>
                    <div className="pb-1.5 pt-1  rounded-md p-2 sm:px-10 mr-2 text-center">
                      {/* 总盈利 <br /> */}
                      {t("accumulated", sourceKey.highFrequency)} (USDT)
                    </div>
                  </div>
                </div>
                {/* <div className="col-span-2">
                  <div className="mb-2  text-black">
                    <div className="font-semibold text-center text-2xl pt-4">
                      {formatDecimalNumber(totalProfit || 0, numberDecimal)}
                    </div>
                    <div className="pb-1.5 pt-1  rounded-md p-2 sm:px-10 mr-2 text-center">
                      {t("accumulated", sourceKey.highFrequency)} (USDT)
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="mt-2">
                {/* <div className="text-center text-xs text-yellow-300 border-b-2 mt-5">
                 
                  {t("highFrequency", sourceKey.highFrequency)}
                </div> */}

                {/* {_renderCodeType()} */}
                {/* {_renderRecordType()} */}
                {showRecord
                  ? _renderRecordList(
                      "2",
                      get(productRecordSelected, "2"),
                      productRecordPagination
                    )
                  : _renderCoinList(
                      "2",
                      get(productCoinSelected, "2"),
                      productCoinPagination
                    )}
              </div>
            </div>
            <DailyProfitReportDrawer
              visible={drawerVisible}
              data={selectedData}
              robotProfiles={robotProfiles}
              profile={profile}
              filterGroup={filterList}
              onClose={() => {
                setDrawerVisible(false);
                setSelectedData({});
              }}
            />
          </NewHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(mapStateToProps, mapDispatchToProps)(RobotPage);

import { set } from "@antv/util";
import { useLocation } from "@reach/router";
import { Drawer, message, Pagination, Spin } from "antd";
import { cloneDeep, get, isEmpty, map } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getDailyProfitReport from "../../../newApi/robots/getDailyProfitReport";
import getProfileRobotProfit from "../../../newApi/robots/getProfileRobotProfit";
import getRobotTransactions from "../../../newApi/robots/getRobotTransactions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { formatDecimalNumber } from "../../../utilities/common-function";
import { numberDecimal } from "../../../utilities/startUp";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

// markup
const PAGESIZE = 10;
const roundingDecimal = 2;
const rounding = false;

const DailyProfitReportDrawer = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [filterGroup, setFilterGroup] = useState({});
  const [detailData, setDetailData] = useState([]);
  const [detailsPage, setDetailsPage] = useState(1);
  const [detailsTotal, setDetailsTotal] = useState(0);
  const [profitTotal, setProfitTotal] = useState(0);
  let data = get(props, "data");
  // let profile = get(props, "profile");
  let robotProfiles = get(props, "robotProfiles");
  let filterGroup_tra = get(props, "filterGroup");

  if (get(filterGroup_tra, "type") === "day") {
    //let temp_filter = cloneDeep(filterGroup);
    let startDate = moment(get(data, "date")).format("YYYY-MM-DD");
    let endDate = moment(get(data, "date")).format("YYYY-MM-DD");
    let startDateTime = moment(startDate + " " + "00:00");
    let endDateTime = moment(endDate + " " + "23:59");
    set(filterGroup_tra, "timestampMin", startDateTime.toDate());
    set(filterGroup_tra, "timestampMax", endDateTime.toDate());
  }

  useEffect(() => {
    let visible_parent = cloneDeep(get(props, "visible"));

    setVisible(props.visible === true);
    setFilterGroup(filterGroup_tra);
    if (visible_parent === true) {
      getDayData((page - 1) * PAGESIZE);
    }
  }, [props.visible]);

  useEffect(() => {
    getDetailsData((detailsPage - 1) * PAGESIZE);
  }, [detailsVisible]);

  useEffect(() => {
    getDayData((page - 1) * PAGESIZE);
  }, [page]);

  useEffect(() => {
    getDetailsData((detailsPage - 1) * PAGESIZE);
  }, [detailsPage]);

  function close() {
    setDataSource([]);
    setTotal(0);
    setPage(1);
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
  }

  function getDayData(skip) {
    setLoading(true);

    if (!isEmpty(robotProfiles)) {
      getRobotTransactions(PAGESIZE, skip, {
        profileIdIn: robotProfiles,
        symbol: get(data, "_id.symbol"),
        ...filterGroup_tra,
        showRecord: true,
        type: "",
      })
        .then((res) => {
          // console.log("res", res);
          let data = get(res, "data");
          if (!isEmpty(data)) {
            setDataSource(data);
            setTotal(get(res, "total"));
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          message.error(err?.message);
          setLoading(false);
        });
    }
  }

  function getDetailsData(skip) {
    //setLoading(true);

    getDailyProfitReport(PAGESIZE, skip, {
      profileIdIn: robotProfiles,
      symbol: get(data, "_id.symbol"),
      ...filterGroup,
      type: "coindate",
    })
      .then((res) => {
        //console.log("res", res);
        let temp_data = get(res, "data");
        if (!isEmpty(temp_data)) {
          setDetailData(temp_data);
          setDetailsTotal(get(res, "total"));
        }
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Drawer
        width="100%"
        visible={visible}
        className="body-no-padding"
        closable={false}
        onClose={() => close()}
      >
        <Layout>
          <Spin spinning={false}>
            <NavHeader
              suffix={
                <div className="justify-end flex items-center">
                  {get(filterGroup_tra, "type") === "coin" ? (
                    <>
                      {/* 币种每日盈利{" "}
                      <MediaQuery maxWidth={440}>
                        <br />
                      </MediaQuery> */}
                      {t("coinDaily", sourceKey.highFrequency)}
                    </>
                  ) : (
                    <>
                      {/* 单日盈利详情{" "}
                      <MediaQuery maxWidth={440}>
                        <br />
                      </MediaQuery> */}

                      {t("oneDay", sourceKey.highFrequency)}
                    </>
                  )}
                </div>
              }
              showBack={true}
              onBack={() => {
                close();
              }}
            >
              <div className="rounded-xl lightgreybox-bg-color box-shadow-center mx-2 py-3 px-2 my-5 ">
                <div className="flex justify-between">
                  <span className="inline-block font-semibold">
                    {get(filterGroup_tra, "type") === "coin"
                      ? get(data, "_id.symbol")
                      : get(data, "date")
                      ? moment(get(data, "date")).format("DD/MM/YYYY")
                      : ""}
                  </span>
                  <span className="inline-block flex font-semibold">
                    {t("total", sourceKey.pairing)} :{" "}
                    {formatDecimalNumber(
                      get(data, "totalProfit"),
                      numberDecimal
                    )}
                  </span>
                </div>

                {isEmpty(dataSource) ? (
                  ""
                ) : get(filterGroup_tra, "type") === "coin" ? (
                  <>
                    {map(dataSource, (item, index) => {
                      return (
                        <div
                          className=" p-2  border-dotted border-b-2  "
                          key={index}
                          style={{ backgroundColor: "#20262F" }}
                        >
                          <div className="flex justify-between">
                            <div className="flex items-center">
                              {get(item, "populatedRobotId.symbol") +
                                "-" +
                                `${
                                  get(item, "populatedRobotId._id")
                                    ? `${get(
                                        item,
                                        "populatedRobotId._id"
                                      ).substring(
                                        get(item, "populatedRobotId._id")
                                          .length - 4
                                      )}`
                                    : ""
                                } ${
                                  get(item, "remark")
                                    ? `(${get(
                                        item,
                                        "populatedRobotId.remark"
                                      )})`
                                    : ""
                                }`}
                            </div>
                            <div
                              className="flex items-center text-xs"
                              // style={{ fontSize: "0.55rem" }}
                            >
                              <span>
                                {" "}
                                {t("orderNo", sourceKey.highFrequency)} :{" "}
                              </span>
                              <span className="">
                                {get(item, "orderId")?.substring(
                                  get(item, "orderId").length - 8
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="flex justify-between">
                            <div className="flex items-center  text-xs">
                              {get(item, "timestamp")
                                ? moment(get(item, "timestamp")).format(
                                    "DD/MM/YYYY hh:mm:ss A"
                                  )
                                : ""}
                            </div>

                            <div className="flex justify-end ">
                              <span
                                className={`${
                                  get(item, "profileTransaction.trxType") ===
                                  "IN"
                                    ? "text-green-400"
                                    : "text-red-400"
                                }  pr-1 text-xs`}
                              >
                                {get(item, "profileTransaction")
                                  ? get(item, "profileTransaction.trxType") ===
                                    "OUT"
                                    ? `(${t(
                                        "deductVSDT",
                                        sourceKey.highFrequency
                                      )} ${formatDecimalNumber(
                                        get(item, "profileTransaction.amount"),
                                        2
                                      )} )`
                                    : `(${t(
                                        "compesated",
                                        sourceKey.highFrequency
                                      )} )`
                                  : ""}
                              </span>{" "}
                              {formatDecimalNumber(
                                get(item, "profit"),
                                numberDecimal
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {map(dataSource, (item, index) => {
                      return (
                        <>
                          <div
                            className=" p-2  border-dotted border-b-2  "
                            key={index}
                            style={{ backgroundColor: "#20262F" }}
                          >
                            <div className="flex justify-between">
                              <div className="flex items-center">
                                {get(item, "populatedRobotId.symbol") +
                                  " " +
                                  `${
                                    get(item, "remark")
                                      ? `(${get(
                                          item,
                                          "populatedRobotId.remark"
                                        )})`
                                      : ""
                                  }`}
                              </div>
                              <div
                                className="flex items-center text-xs"
                                // style={{ fontSize: "0.55rem" }}
                              >
                                <span>
                                  {" "}
                                  {t("orderNo", sourceKey.highFrequency)} :{" "}
                                </span>
                                <span className="">
                                  {get(item, "_id")?.substring(get(item, "_id").length - 6)}
                                </span>
                              </div>
                            </div>
                            <div className="flex justify-between">
                              <div className="flex items-center  text-xs">
                                {get(item, "timestamp")
                                  ? moment(get(item, "timestamp")).format(
                                      "DD/MM/YYYY hh:mm:ss A"
                                    )
                                  : ""}
                              </div>

                              <div className="flex justify-end ">
                                <span className="text-green-400 pr-1">
                                  {get(item, "profileTransaction")
                                    ? `(${t(
                                        "deductVSDT",
                                        sourceKey.highFrequency
                                      )}) `
                                    : ""}
                                </span>{" "}
                                {formatDecimalNumber(
                                  get(item, "profit"),
                                  numberDecimal
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}

                <div className="flex justify-end items-center my-5">
                  <span className="inline-block ">
                    <Pagination
                      simple
                      size="small"
                      total={total}
                      pageSize={PAGESIZE}
                      current={page}
                      showSizeChanger={false}
                      onChange={(page) => {
                        setPage(page);
                      }}
                    />
                  </span>
                </div>
              </div>

              <Drawer
                width="100%"
                visible={detailsVisible}
                className="body-no-padding"
                onClose={() => {
                  setDetailsVisible(false);
                  setDetailData([]);
                  setDetailsPage(1);
                  setDetailsTotal(0);
                }}
                closable={false}
              >
                <Layout>
                  <Spin spinning={false}>
                    <NavHeader
                      suffix={
                        <span className="inline-block text-base text-right font-semibold">
                          {/* 币种盈利详情{" "}
                          <MediaQuery maxWidth={440}>
                            <br />
                          </MediaQuery> */}
                          {t("coinAll", sourceKey.highFrequency)}
                        </span>
                      }
                      showBack={true}
                      onBack={() => {
                        setDetailsVisible(false);
                        setDetailData([]);
                        setDetailsPage(1);
                        setDetailsTotal(0);
                      }}
                    >
                      <div className="rounded-xl bg-white box-shadow-center mx-1 py-3 px-2 my-5 ">
                        <div className="flex justify-between">
                          <span className="inline-block font-semibold">
                            {get(data, "_id.symbol")}
                          </span>
                          <span className="inline-block flex font-semibold">
                            Total :{" "}
                            {formatDecimalNumber(profitTotal, numberDecimal)}
                          </span>
                        </div>

                        {isEmpty(detailData) ? (
                          ""
                        ) : (
                          <>
                            {map(detailData, (item, index) => {
                              return (
                                <div
                                  className=" rounded-3xl p-3 my-3 flex justify-around items-center border"
                                  key={index}
                                >
                                  <span className="inline-block mr-4">
                                    {get(item, "binanceStocks.transactionTime")
                                      ? moment(
                                          get(
                                            item,
                                            "binanceStocks.transactionTime"
                                          )
                                        ).format("DD/MM/YYYY")
                                      : ""}
                                  </span>
                                  <span className="inline-block mr-4">
                                    {get(item, "binanceStocks.transactionTime")
                                      ? moment(
                                          get(
                                            item,
                                            "binanceStocks.transactionTime"
                                          )
                                        ).format("hh:mm:ss A")
                                      : ""}
                                  </span>
                                  <span className="inline-block mr-4">
                                    {formatDecimalNumber(
                                      get(item, "binanceStocks.profit"),
                                      numberDecimal
                                    )}
                                  </span>
                                </div>
                              );
                            })}
                          </>
                        )}

                        <div className="flex justify-end items-center my-5">
                          <span className="inline-block ">
                            <Pagination
                              simple
                              size="small"
                              total={detailsTotal}
                              pageSize={PAGESIZE}
                              current={detailsPage}
                              showSizeChanger={false}
                              onChange={(page) => {
                                setDetailsPage(page);
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </NavHeader>
                  </Spin>
                </Layout>
              </Drawer>
            </NavHeader>
          </Spin>
        </Layout>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyProfitReportDrawer);

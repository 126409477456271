import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { get } from "lodash";
import React from "react";

// markup
const NavHeader = (props) => {
  const location = useLocation();
  const currentPath =
    location.pathname[location.pathname.length - 1] === "/" &&
    location.pathname.length > 1
      ? location.pathname.slice(0, -1)
      : location.pathname;

  return (
    <React.Fragment>
      <div className="theme-bg-color pb-2">
        {props.header ? (
          props.header
        ) : (
          <div className="flex justify-start items-center px-2 pt-4 ">
            {props.showBack ? (
              <>
                <span
                  className="flex items-center arrowbox-bg-color p-2 rounded-full  cursor-pointer"
                  onClick={(e) => {
                    if (props.onBack) {
                      props.onBack();
                    } else {
                      if (typeof window !== `undefined`) {
                        navigate(-1);
                      }
                    }
                  }}
                >
                  {get(location, "state.hideBack") ? null : (
                    <ArrowLeftOutlined className="" style={{ fontSize: 20 }} />
                  )}
                </span>
                <span className="inline-block text-white pl-4 text-base">
                  {props.suffix}
                </span>
                <span className="inline-block pl-4" style={{ width: "25px" }}>
                  {props.after}
                </span>
              </>
            ) : (
              <span className="inline-block">{props.suffix}</span>
            )}
          </div>
        )}
      </div>
      <div className="relative theme-bg-color" style={{ color: "white" }}>
        <div className="">{props.children}</div>
      </div>
    </React.Fragment>
  );
};

export default NavHeader;
